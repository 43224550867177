.emergency{
    padding: 4rem 0;
}
.emergency .container{
    gap: 5rem;
}
.emergency-sec-title{
    font-size: 26px;
    line-height: 38px;
    margin-bottom: 14px;
}
.emergency-sec-txt{
    margin-bottom: 20px;
}
.emergency-sec-number{
    color:var(--secondary);
    font-size:26px;
    margin-bottom: 18px;
}
.openhour-list{
    list-style-type: none;
}
.openhour-list li{
   display: flex;
   justify-content: space-between;
   border-bottom: 1px dotted lightgray;
   line-height: 3rem;
 
}