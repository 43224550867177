/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype'); 
}
/* Roboto */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype'); 
}

:root {
  --primary: #396CF0;
  --secondary: #1C58EF;
  --third: #E2EAFF;
  --textcolor: #646464;
  --headingcolor: #1E2023;
  --bgcolor:#F5F8FA;
}


* {
  margin:0;
  padding:0;
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto';
}
body  {
  font-family: 'Poppins';
  font-size: 1.1rem;
}

.container{
  width: 1270px;
  margin:0 auto;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.btn {
  background-color: var(--primary);
  padding: 1rem 2rem; 
  border:none;
  border-radius: 6px;  
  color:#fff;
  font-size: 18px;
}
.btn-white{
  background-color:#fff;
  color:var(--primary);
}
.link{
  text-decoration: none;
  color:inherit;
}
.grid3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

 