.slider{
    width:100%;
    height: 880px;
    padding-top: 8%;
}
.slider-content{
    width: 620px;
    height: 430px;
    padding:3rem;
    background-color: var(--primary);
    border-radius: 6px;
    color:#fff;
}
.slider-title{
    font-size: 42px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 16px;
}
.slider-txt{
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 32px;
}

.swiper .swiper-button-prev, .swiper .swiper-button-next{
    background-color: var(--primary);
    width: 60px;
    height: 60px;
    opacity: 1;
    border-radius: 50%;
    color:#fff;
}
.swiper .swiper-button-prev::after, .swiper .swiper-button-next::after{
    font-size: 24px;
    font-weight: 900;
}
.swiper .swiper-button-next{
   right: 4%;
}
.swiper .swiper-button-prev{
   left:4%;
}

.swiper .swiper-button-disabled{
opacity: 0.5;
background-color: #fff;
color:var(--primary)
}

