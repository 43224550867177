
.nav-top{
    height: 56px;
    border-bottom: 1px solid lightgray;
}
.nav-top .flex-center{
    justify-content: space-between;
    height: inherit;
}
.nav-top-contact{
    gap: 1rem;
}
.nav-top-gap{
    gap: 0.3rem;
}
.nav-bottom{
    height: 144px;
    justify-content: space-between;
}
.icon{
    color: var(--primary);
}

 